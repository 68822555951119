<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="true"
            type="search"
            codeGroupCd="LAW_REGISTER_ITEM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            label="업무구분"
            name="lawRegisterItemTypeCd"
            v-model="searchParam.lawRegisterItemTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            label="평가년도"
            type="year"
            default="today"
            name="evalYear"
            v-model="searchParam.evalYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="평가부서"
            name="targetDeptCd"
            :isFirstValue="false"
            v-model="searchParam.targetDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="COMPLIANCE_EVAL_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            label="정기/비정기"
            name="complianceEvalTypeCd"
            v-model="searchParam.complianceEvalTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            stepperGrpCd="SAI_COMPLIANCE_EVAL_TABLE_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            label="LBLSTEPPROGRESS"
            name="saiComplianceEvalTableStepCd"
            v-model="searchParam.saiComplianceEvalTableStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!--법규등록부 및 준수평가표 목록-->
    <c-table
      ref="table"
      title="LBL0010051"
      tableId="processManual"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--등록-->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'compliance-eval-table',
  props: {
    evalTask: {
      type: Object,
      default(){
        return {
          lawRegisterItemTypeCd: '',
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: '',
        saiComplianceEvalTableStepCd: null,
        evalYear: '',
        targetDeptCd: '',
        lawRegisterItemTypeCd: null,
        complianceEvalTypeCd: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
          },
          {
            name: 'evalYear',
            field: 'evalYear',
            //평가년도
            label: '평가년도',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'complianceEvalTypeName',
            field: 'complianceEvalTypeName',
            label: '정기/비정기',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'quarterName',
            field: 'quarterName',
            label: '평가분기',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'targetDeptName',
            field: 'targetDeptName',
            label: '평가부서',
            align: 'left',
            style: 'width:200px',
            type: "link",
            sortable: true,
          },
          {
            name: 'startDt',
            field: 'startDt',
            label: '평가일자',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'saiComplianceEvalTableStepName',
            field: 'saiComplianceEvalTableStepName',
            //진행상태
            label: 'LBLPROGRESS',
            style: 'width:100px',
            align: 'center',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL00001609',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.evalTable.complianceEvalTable.list.url;

      this.searchParam.lawRegisterItemTypeCd = this.evalTask.lawRegisterItemTypeCd;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(row) {
      this.popupOptions.target = () => import(`${"./complianceEvalTableDetail.vue"}`);
      this.popupOptions.title = 'LBL0010054';   //법규등록부 및 준수평가표
      this.popupOptions.param = {
        saiComplianceEvalTableId: row ? row.saiComplianceEvalTableId : null,
        lawRegisterItemTypeCd: this.evalTask.lawRegisterItemTypeCd,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
